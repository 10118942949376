import type {Options as ClientOptions} from '@sentry/vue';
import * as Sentry from '@sentry/vue';
import type {Integration} from '@sentry/types';
import {options} from '@malt/alerting-shared';
import type {Options as CustomOption} from './index';

export function setUpAlerting({configuration, app, router, connectedUser}: CustomOption): void {
    if (!configuration || !configuration.dsn || !configuration.environment || !app) {
        throw new Error('Invalid configuration, could not initialize alerting service, required option missing');
    }

    const integrations: Integration[] = [
        Sentry.browserTracingIntegration({router}),
        Sentry.extraErrorDataIntegration(),
        ...(configuration.captureConsoleLevels ? [Sentry.captureConsoleIntegration({levels: configuration.captureConsoleLevels})] : []),
    ];

    const config: Partial<ClientOptions> = {
        dsn: configuration.dsn,
        environment: configuration.environment,
        app,
        // @ts-ignore
        integrations,
        tracesSampleRate: 0.001,
        ...options,
    };

    // @ts-ignore
    Sentry.attachErrorHandler(app, {logErrors: true, attachProps: true});

    Sentry.init(config);

    Sentry.setUser(Object.assign({}, connectedUser || {}));

    if (configuration.application) {
        Sentry.setTag('application', configuration.application);
    } else {
        // @ts-ignore
        Sentry.setTag('application', app.name);
    }

    Sentry.setTag('layer', 'client');

    // @ts-ignore
    window.captureException = (err, tags?: Record<string, any>) => {
        if (tags) {
            Sentry.withScope((scope: any) => {
                scope.setTags(tags);
                Sentry.captureException(err);
            });
        } else {
            Sentry.captureException(err);
        }
    };
}

export function setUser({connectedUser}: Pick<CustomOption, 'connectedUser'>): void {
    Sentry.setUser(Object.assign({}, connectedUser || {}));
}
