export const options = {
    ignoreErrors: [
        /:\sUET/,
        /'?UET'?\s/,
        /Can't find variable: heap/,
        /Erreur non spécifiée/i,
        /unspecified error/i,
        /Non-Error exception captured with keys/,
        /NS_ERROR_.*/,
        /ReportingObserver/,
        /Failed to fetch/,
        /NetworkError when attempting to fetch resource/,
        /Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing./,
        /AlgoliaSearchRequestTimeoutError: Request timed out before getting a response/,
        /webkit\.messageHandlers\.contextMenuMessageHandler\.postMessage/,
        /Can't find variable: twttr/,
        /cookie-verifier/i,

        // Most of the time, it happens on Safari Mobile 14...
        /e.action.startsWith/,

        // the 2 following errors may appear when the server crashes somehow: it returns HTML
        /SyntaxError: illegal character\s*.*:1:0/,
        /SyntaxError: Unexpected token '<'/,

        /AbortError/,
        /UnknownError/,
        /Permission denied to access property "getAttribute"/,
        /ResizeObserver loop completed with undelivered notifications/,
        /ResizeObserver loop limit exceeded/,

        // This issue is due to Google Translate trying to translate elements in svg, unfortunately, `className.indexOf` does
        // not exist on these elements...
        // https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
        // https://github.com/airbnb/lottie-web/issues/1134
        /className\.indexOf is not a function/,

        // Lazy loading errors with stencil components
        /undefined is not an object \(evaluating 'o.isProxied'\)/,
        /[a-z]\.isProxied/,
        /'isProxied' of undefined/,
        /\(reading 'isProxied'\)/,
        /* Error returned by stencil runtime when lazy-loading fails. It's maybe not enough specific, but blacklisting the URl is not enough */
        /l is undefined/,

        // Might appear when 2 instances of sentry are loaded: https://github.com/getsentry/sentry-javascript/issues/2622
        /r._mergeOptions is not a function/,

        // Error seems to be related to BlackBox extension (https://www.useblackbox.io/)
        // https://stackoverflow.com/questions/74485409/vm4287-uncaught-typeerror-cannot-read-properties-of-null-reading-codemirror
        /Cannot read properties of null \(reading 'CodeMirror'\)/,

        // This error is not user facing: it's linked to bot crawling and not preloading css on there side
        /Unable to preload/i,

        // This is a 404 error that's triggered from the /password-renewal/xxx-token-xxx page when token is invalid, by Nuxt.
        // It should not be logged as an error
        /Password renewal token invalid/,

        // The Nuxt framework periodically check for /_nuxt/builds/latest.json. The request may fail if the browser window is opened and Cloudflare bans the user after the Malt page has been opened.
        /\[GET] "(\/[^/]+)+\/_nuxt\/builds\/latest\.json": (403|404)/,
        /\[GET] "(\/[^/]+)+\/_nuxt\/builds\/meta\/[^/]+\.json": (403|404)/,
        // visitors can try to access non existing profiles or profiles that have been deleted this is normal and shouldn't generate alerts
        // we still have dd logs to monitor the volume of such codes if needed
        /\[GET] "\/profile\/public-api\/([^/]+)\/url[^"]*": (410|404).*/,

        // TODO: remove both with Nuxt 3.12
        /Cannot read properties of undefined \(reading 'appMiddleware'\)/,
        /undefined is not an object \(evaluating '[A-Z].appMiddleware'\)/,

        // Ignoring noise from https://malt.sentry.io/issues/5412669907 (coming from illegitimate use: auto-scan for vulnerabilities through DOM injections)
        /Cannot read properties of undefined \(reading '_vts'\)/,
        // See https://sentry.io/answers/load-failed-javascript/
        /: <no response> Load failed/,
        /TypeError: Load failed/,
    ],
    allowUrls: [
        // scheme: ws:// or http://, secure or not (s)
        // domain: malt.yyy or xxx.malt.yyy or xxx-malt.yyy
        // port accepted
        // followed by anything not part of host name & port
        /^(http|ws)s?:\/\/.*malt\.[^.]+(\/|\?|$)/,
        // webpack:// and webpack-internal:// support
        // should be whitelisted to support usage with source maps
        /^webpack(-internal)?:\/\//,
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Safari extensions
        /safari-extension:\/\//i,
        // Ignore vendors errors
        /https:\/\/cdn\.malt\.com\/.+\/js\/chunk-vendors\..+\.js/,
        // Should be able to ignore stencil errors due to lazy-loading fail
        /@maltjoy\/core\/.*\/joy\/@lazy-browser-entrypoint/,
        /.*\/npm\/@maltjoy\/core\/[0-9.]+\/node_modules\/\.pnpm\/@stencil\+core@[0-9.]+\/.*/,
    ],
    maxValueLength: 5000,
    beforeSend: (event) => {
        if (shouldIgnoreEvent(event)) {
            return null;
        }
        const appName = '{{ tags.application }}';
        event.fingerprint = ['{{ default }}', appName];
        return event;
    },
};

// see @sentry/types/dist/event.d.ts for the definition of what an event is
function shouldIgnoreEvent(event) {
    const exception = event.exception && event.exception.values && event.exception.values[0];
    if (!exception) {
        return true;
    }

    // No stacktrace: likely a browser error, not our problem
    if (!exception.stacktrace || !exception.stacktrace.frames || exception.stacktrace.frames.length === 0) {
        return true;
    }

    const stracktraceFilesToExclude = [/addthis_widget\.js/, /\/ext\/.*.js/, /\/frame\..*\.js/];
    const stackFrames = exception.stacktrace.frames;
    if (stackFrames.some((frame) => frame.filename && stracktraceFilesToExclude.some((re) => re.test(frame.filename)))) {
        return true;
    }

    const urlsToExclude = [/abtesty\.com/];
    const latestXhrOrFetchUrls = (event.breadcrumbs || [])
        // bc.category is one of: 'fetch', 'xhr'
        .filter((bc) => bc.type === 'http' && (bc.data || {}).url)
        .map((bc) => bc.data.url);
    const latestXhrOrFetchUrl = latestXhrOrFetchUrls.length && latestXhrOrFetchUrls[latestXhrOrFetchUrls.length - 1];

    if (latestXhrOrFetchUrl && urlsToExclude.some((re) => re.test(latestXhrOrFetchUrl))) {
        return true;
    }

    // Browser Filter
    const browserNamesToExclude = ['Instagram'];
    const browserName = event.contexts && event.contexts.browser && event.contexts.browser.name;
    if (!!browserName && browserNamesToExclude.some((browserNameToExclude) => browserNameToExclude === browserName)) {
        return true;
    }

    // Ignoring all errors coming from <Swiper> component, in the meantime (will be updated or replaced)
    if (event && event.contexts && event.contexts.vue && event.contexts.vue.componentName) {
        const vueComponentName = event.contexts.vue.componentName;
        if (vueComponentName === '<Swiper>') {
            return true;
        }
    }

    return false;
}
